.main{
   width: 100%;
   display: flex;
   flex-direction: column;
   justify-content: flex-start;
    margin: 0 auto;
      height: 40px;
      position: relative;
      color: black;
}
.input{
    min-width: 170px;
    padding-right: 0rem;
    
}
input{
    padding: 0px;
    padding-left: 10px;
    width: 100%;
    border-radius: 5px;
    border: 1px solid #08231B;
    background-color: white;
    font-weight: bold;
    font-family: Rubik;
    /* box-sizing: border-box; */
    /* outline: none; */
}



#date-time-input {
    height: 61px;
    font-size: 16px;
    box-sizing: border-box;
    outline: none;
  }
  /*fontsize: {xs:'10px',md:'11px',lg:'12px',xl:'16px'} */

#date-time-input-drawer {
    height: 42px;
    font-size: 15px;
    border: 2px solid #08231B;
}

input::placeholder{
    color: #08231B;
}

#date-time-input:focus {
    border: 2px solid #08231B;
  }

#date-time-input-drawer:focus {
    border: 1.5px solid #08231B;
}

.display {
    z-index: 1200;
    margin-top: 60px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    min-width: 500px;
    /* max-width: 500px; */
    width: 50%;
    border-radius: 5px;
    border: 1px solid black;
    position: absolute;
    background-color: white;
    /* ///////////////////// */
    /* height: 257px; */
}

.display-drawer{
    z-index: 1200;
    margin-top: 48px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    min-width: 350px;
    /* max-width: 500px; */
    width: 40%;
    border-radius: 5px;
    border: 1px solid black;
    position: absolute;
    background-color: white;
}

@media only screen and (max-width: 1919px) {
    .display{
        margin-top: 50px;
        width: 45%;
        min-width: 400px;
    }
     .main{
        height: 38px;
    }
    #date-time-input{
        font-size: 13px;
        height: 50px;
    }

    #date-time-input-drawer {
        font-size: 12px;
        height: 31px;
        border: 1.5px solid #08231B;
    }
}

@media only screen and (max-width: 1279px) {
    .display{
        margin-top: 50px;
        width: 40%;
        min-width: 350px;
    }
    .main{
        height:30px
    }
    #date-time-input{
        font-size: 12px;
        /* height: 29px; */
        height: 50px;
    }

    #date-time-input-drawer {
        font-size: 10px;
        height: 29px;
        border: 1.3px solid #08231B;
    }
}

@media only screen and (max-width: 959px) {
    .display{
        margin-top: 55px;
        width: 40%;
        min-width: 350px;
    }
    .main{
      height:35px
  }
  #date-time-input{
      font-size: 12px;
      /* height: 29px; */
      height: 50px;
  }

  #date-time-input-drawer {
    font-size: 10px;
    height: 29px;
    border: 1.3px solid #08231B;
}
}

