/* App.css */

.App {
  /* padding: 20px; */
  font-family: Arial, sans-serif;
}

h2 {
  text-align: center;
  margin-bottom: 20px;
}

.responsive-table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed; /* Ensures fixed layout for consistent column widths */
}

/* .responsive-table thead {
 
} */

.responsive-table th,
.responsive-table td {
  padding: 12px;
  text-align: left;
  word-wrap: break-word; /* Ensures content wraps within cells */
}

.responsive-table tr {
  border-bottom: 1px solid #ddd; /* Horizontal line between rows */
}

.file-info {
  display: flex;
  flex-direction: column;
}

.file-name {
  font-weight: bold;
}

.file-type {
  font-size: 0.9em;
  color: #333;
  margin-top: 2px;
}

.file-details {
  font-size: 0.85em;
  color: #555;
  margin-top: 4px;
}

.options {
  text-align: right;
  cursor: pointer;
  color: #555;
}

/* Remove vertical borders */
.responsive-table th,
.responsive-table td {
  border: none; /* No vertical lines */
}

/* Responsive Styles for devices (max-width: 800px) */
@media screen and (max-width: 800px) {
  /* Use Flexbox for thead to align with tbody */
  .responsive-table thead {
    display: flex;
    width: 100%;
  }

  .responsive-table thead th {
    flex: 2 1 60%; /* Name column takes more space */
    padding: 12px 5px;
  }

  .responsive-table thead th:nth-child(2),
  .responsive-table thead th:nth-child(3) {
    flex: 1 1 20%; /* File Type and Options columns */
  }

  /* Use Flexbox for tbody rows */
  .responsive-table tbody tr {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    padding: 10px 0;
    box-sizing: border-box;
  }

  .responsive-table tbody tr:last-child {
    border-bottom: none;
 
  }

  .responsive-table tbody tr td {
    flex: 2 1 60%; /* Name column */
    padding: 5px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .responsive-table tbody tr td:nth-child(2),
  .responsive-table tbody tr td:nth-child(3) {
    flex: 1 1 20%; /* File Type and Options */
    justify-content: center;
    align-items: center;
  }

  /* Specific styles for Options column */
  .responsive-table tbody tr td[data-label="Options"] {
    justify-content: center;
    align-items: center;
  }

  /* Adjust text alignment */
  .responsive-table tbody tr td {
    text-align: left;
  }

  .options {
    text-align: center;
  }
}

/* Further Adjustments for Small Screens (max-width: 600px) */
@media screen and (max-width: 600px) {
  /* Further adjust column widths for smaller screens */
  .responsive-table thead th {
    flex: 2 1 60%; /* Name column */
  }

  .responsive-table thead th:nth-child(2),
  .responsive-table thead th:nth-child(3) {
    flex: 1 1 20%; /* File Type and Options columns */
  }

  .responsive-table tbody tr td {
    flex: 2 1 60%; /* Name column */
  }

  

  .responsive-table tbody tr td:nth-child(2),
  .responsive-table tbody tr td:nth-child(3) {
    flex: 1 1 20%; /* File Type and Options columns */
  }

  /* /////////////////////////////////////my css////////////////////// */


  
}

/* Further Adjustments for Very Small Screens (max-width: 350px) */
@media screen and (max-width: 350px) {
  .responsive-table thead {
    flex-direction: column;
  }

  .responsive-table thead th {
    flex: 1 1 100%;
    text-align: left;
    padding: 8px 0;
  }

  .responsive-table tbody tr {
    flex-direction: column;
    align-items: flex-start;
  }

  .responsive-table tbody tr td {
    flex: 1 1 100%;
    padding: 6px 0;
  }

  .responsive-table tbody tr td[data-label="Options"] {
    align-self: flex-end;
    margin-top: 5px;
  }

  /* Ensure Options icon is centered */
  .options {
    text-align: center;
  }
}

  