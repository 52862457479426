.custom-radio {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.custom-radio input[type="radio"] {
    position: absolute;
    opacity: 0;
}

.custom-radio-button {
    width: 20px;
    height: 20px;
    border: 2px solid #000;
    border-radius: 50%;
    margin-right: 10px;
    cursor: pointer;
}

.custom-radio input[type="radio"]:checked + .custom-radio-button {
    background-color: #000;
}

.custom-label {
    cursor: pointer;
}

.custom-radio input[type="radio"]:checked + .custom-radio-button::after {
    content: "";
    display: block;
    width: 12px;
    height: 12px;
    background-color: #fff;
    border-radius: 50%;
    margin: 2px;
}