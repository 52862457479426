.App {
  text-align: center;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.linear-gradient{
  background: 
  linear-gradient(0deg, white, transparent, transparent 80%),
  /* linear-gradient(0deg, white, #ffffff20, #ffffff05 97%), */
  linear-gradient(0deg, white, #ffffff30, #ffffff10 97%),
  /* linear-gradient(0deg, white, #ffffff10, #ffffff10 97%), */
  linear-gradient(90deg,  #00CEFF, #00D744, #00CEFF00 80%), 
  linear-gradient(96deg, #00D744 , #00D74480 30%), 
  /* linear-gradient(135deg,  #403E3E70,#403E3E80 70%),  */
  /* linear-gradient(310deg, #00000090, #000000  60%), */
  linear-gradient(310deg, #00000090, #000000  60%);
  /* linear-gradient(0deg, white, transparent, transparent 99%); */
}
.linear-gradient{
  background: 
  linear-gradient(0deg, white, transparent, transparent 80%),
  /* linear-gradient(0deg, white, #ffffff20, #ffffff05 97%), */
  linear-gradient(0deg, white, #ffffff30, #ffffff10 97%),
  /* linear-gradient(0deg, white, #ffffff10, #ffffff10 97%), */

  linear-gradient(90deg,  #00CEFF, #00D744, #00CEFF00 80%), 
  linear-gradient(96deg, #00D744 , #00D74480 30%), 
  /* linear-gradient(135deg,  #403E3E70,#403E3E80 70%),  */
  /* linear-gradient(310deg, #00000090, #000000  60%), */

  linear-gradient(310deg, #00000090, #000000  60%);
  /* linear-gradient(0deg, white, transparent, transparent 99%); */

}

.linear-gradient-dialog{
  background:  
  linear-gradient(0deg, white, transparent, transparent 80%),
  /* linear-gradient(0deg, white, #ffffff20, #ffffff05 97%), */
  linear-gradient(0deg, white, #ffffff30, #ffffff10 97%),
  linear-gradient(0deg, white, #ffffff40, #ffffff20 97%),
  linear-gradient(to right, #00CEFF70,  #00D74460, #70707080) ;
}

.linear-gradient-drawer{
  background:  
  linear-gradient(0deg, white, transparent, transparent 80%),
  /* linear-gradient(0deg, white, #ffffff20, #ffffff05 97%), */
  linear-gradient(0deg, white, #ffffff30, #ffffff10 97%),
  linear-gradient(0deg, white, #ffffff40, #ffffff20 97%),
  linear-gradient(to right, #00CEFF50,  #00D74450, #00D74440, #ffffff10, #ffffff70, white);
}

.linear-gradient-drawer-bottom{
  background:  
  linear-gradient(180deg, #ffffff26 ,#ffffff26  ),
  linear-gradient(120deg, #00CEFF36,  transparent, transparent,transparent  70%),
  linear-gradient(150deg, transparent,  #00D74436, #EBEBEB50, transparent,#ffffff20, transparent  90%),
  linear-gradient(150deg, transparent,#ffffff20 ,transparent,transparent, #00CEFF25 ,  transparent,transparent,transparent 140%),
  linear-gradient(120deg, transparent ,transparent, transparent,  #ffffff20, #00D74436  ,transparent 120%);

}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}


.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


div.spinner {
  position: relative;
  width: 180px;
  height: 180px;
  display: inline-block;
  margin-left: 50%;
  margin-right: 50%;
  /* background: #000; */
  padding: 10px;
  border-radius: 10px;
}

div.spinner div {
  width: 8%;
  height: 16%;
/*   background: #FFF; */
  border: 5px solid #fff;
  position: absolute;
  /* background-color: #000000; */
  left: 49%;
  top: 43%;
  /* opacity: 1; */
  animation: fade 1s ease-in infinite;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -webkit-animation: fade 1s ease-in infinite;
}

@keyframes fade {
  from { background-color: #fff; opacity:1;}
  to { background-color: transparent; opacity: 1;}
}

div.spinner div.bar1 {
  transform:rotate(0deg) translate(0, -130%);
  animation-delay: 0s;
  -webkit-transform:rotate(0deg) translate(0, -130%);
  -webkit-animation-delay: 0s;
}    

div.spinner div.bar2 {
  transform:rotate(45deg) translate(0, -130%); 
  animation-delay: -0.875s;
  -webkit-transform:rotate(45deg) translate(0, -130%); 
  -webkit-animation-delay: -0.875s;
}

div.spinner div.bar3 {
  transform:rotate(90deg) translate(0, -130%); 
  animation-delay: -0.75s;
  -webkit-transform:rotate(90deg) translate(0, -130%); 
  -webkit-animation-delay: -0.75s;
}
div.spinner div.bar4 {
  transform:rotate(135deg) translate(0, -130%); 
  animation-delay: -0.625s;
  -webkit-transform:rotate(135deg) translate(0, -130%); 
  -webkit-animation-delay: -0.625s;
}
div.spinner div.bar5 {
  transform:rotate(180deg) translate(0, -130%); 
  animation-delay: -0.5s;
  -webkit-transform:rotate(180deg) translate(0, -130%); 
  -webkit-animation-delay: -0.5s;
}
div.spinner div.bar6 {
  transform:rotate(225deg) translate(0, -130%); 
  animation-delay: -0.375s;
  -webkit-transform:rotate(225deg) translate(0, -130%); 
  -webkit-animation-delay: -0.375s;
}
div.spinner div.bar7 {
  transform:rotate(270deg) translate(0, -130%); 
  animation-delay: -0.25s;
  -webkit-transform:rotate(270deg) translate(0, -130%); 
  -webkit-animation-delay: -0.25s;
}
div.spinner div.bar8 {
  transform:rotate(315deg) translate(0, -130%); 
  animation-delay: -0.125s;
  -webkit-transform:rotate(315deg) translate(0, -130%); 
  -webkit-animation-delay: -0.125s;
}

#assets.MuiPaper-elevation{
  border-bottom: 2px solid #08231B !important;
  box-shadow: none !important;
}

