*, *::before, *::after{
    box-sizing: border-box;
  }
  
  .video-container{
    width: 70%;
    max-width: 865px;
    background-color: #000;
    display: flex;
    justify-content: center;
    margin-inline: auto;
    position: relative;
    max-height: 60vh;
  }
  
  .play-icon-container{
    position: absolute;
    top: 50%;
    left:50%;
    transform: translate(-50%, -50%);
  }
  
  .video{
    width: 100%;
    aspect-ratio: 16 / 9;
  }
  
  .video-controls-container{
    position: absolute;
    bottom: -80px;
    left: 0;
    right: 0;
    /* background-color: #ffffffbf; */
    z-index: 100;
    /* opacity: 0; */
    /* transition: opacity 150ms ease-in-out; */
  }
  
  .video-container.fullscreen .video-controls-container{
    bottom: 0;
  }
  
  .video-container.full-screen {
    max-height: 100vh;
    max-width: initial;
    width: 100%;
  }
  
  .video-container:hover .video-controls-container,
  .video-container:focus-within .video-controls-container,
  .video-container.paused .video-controls-container,
  .video-container.fullscreen .video-controls-container
  {
    opacity: 1;
  }
  
  .video-controls-container .controls{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  
  .video-controls-container .controls .volume-container{
    display: flex;
    align-items: center;
    gap: 5px;
    /* width: 10%; */
    /* position: relative; */
  }
  
  .volume-slider{
    width: 80px;
    display: none;
    transition: all 350ms ease-in-out;
    /* position: absolute; */
    /* left: 0; */
    /* transform-origin: left; */
    /* transform: scaleX(0); */
  }
  
  .volume-container input[type="range"] {
    appearance: none;
    -webkit-appearance: none;
    margin-right: 15px;
    /* width: 200px; */
    height: 7px;
    background: rgba(255, 255, 255, 0.6);
    border-radius: 5px;
    background-image: linear-gradient( #000,  #000);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding-left: 0;
  }
  
  /* Input Thumb */
  .volume-container input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background:  #000;
    cursor: pointer;
    /* box-shadow: 0 0 2px 0 #555; */
    transition: background .3s ease-in-out;
  }
  
  .volume-container input[type="range"]::-moz-range-thumb {
    appearance: none;
    -webkit-appearance: none;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background:  #000;
    cursor: pointer;
    /* box-shadow: 0 0 2px 0 #555; */
    transition: background .3s ease-in-out;
  }
  
  .volume-container input[type="range"]::-ms-thumb {
    appearance: none;
    -webkit-appearance: none;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background:  #000;
    cursor: pointer;
    /* box-shadow: 0 0 2px 0 #555; */
    transition: background .3s ease-in-out;
  }
  
  .volume-container input[type="range"]::-webkit-slider-thumb:hover {
    background:  #000;
  }
  
  .volume-container input[type="range"]::-moz-range-thumb:hover {
    background:  #000;
  }
  
  .volume-container input[type="range"]::-ms-thumb:hover {
    background: #000;
  }
  
  /* Input Track */
  .volume-container input[type=range]::-webkit-slider-runnable-track  {
    -webkit-appearance: none;
    box-shadow: none;
    border: none;
    background: transparent;
  }
  
  .volume-container input[type=range]::-moz-range-track {
    appearance: none;
    -webkit-appearance: none;
    box-shadow: none;
    border: none;
    background: transparent;
  }
  
  .volume-container input[type="range"]::-ms-track {
    appearance: none;
    -webkit-appearance: none;
    box-shadow: none;
    border: none;
    background: transparent;
  }
  
  .volume-icon-box:hover .volume-slider{
    width: 80px;
    display: block;
    /* transform: scaleX(1); */
  }
  
  
  .video-controls-container .duration-container{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
  }
  
  /* .timeline-container { */
    /* margin-inline: 0.5rem; */
  /* } */
  
  .timeline-container .timeline {
    height: 5px;
    /* background-color: #888; */
    width: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
  
  }
  
  .time{
    background-color: #aaa;
    height: 3px;
    width: 100%;
    position: relative;
    /* margin:  0.3rem 0; */
    padding: 0 5px;
    z-index: 200;
    transition: height 150ms ease-in-out;
  }
  
  .timeline:hover .time{
    height: 100%;
  }
  
  .time::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    top: 0;
    right: calc(100% - var(--preview-position)*100%);
    background-color: #888;
    display: none;
  }
  
  .time::after{
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    top: 0;
    right: calc(100% - var(--progress-position)*100%);
    background-color: #000;
    border-radius: 5px;
  
  } 
  
  .time .thumb{
    --scale: 0;
    position: absolute;
    transform: translateX(-50%) scale(var(--scale));
    height: 300%;
    top: -100%;
    left: calc(var(--progress-position)*100%);
    /* left: 0; */
    /* right: calc(100% - var(--progress-position)*101%); */
    background-color: #fff;
    border-radius: 50%;
    transition: transform 150ms ease-in-out;  
    aspect-ratio: 1 / 1;
    border: 2px solid black;
    z-index: 300;
  }
  
  .timeline:hover .thumb{
    --scale: 1
  }
  
  .timeline:hover .time::before{
    display: block;
  }
  
  
  
  /* .video-container{
      position: relative;
      width: 62%;
      max-width: 865px;
  
  } */
  
  /* .video{
      position: absolute;
      margin-bottom: 50px;
      height:
  } */
  
  /* .video-controls-container{
  
    position: absolute;
    width: 100%;
    bottom: -60px;
    left: 0;
    right: 0;
    z-index: 1000;
  } */
  
  /* .video-controls-container .controls-container{
    
  } */
    
    /* The time controls section */
    
    /* .timeline-container {
      min-height: 20px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      bottom: 0.5rem;
    } */
    
    /* .time_progressbarContainer {
      background-color: gray;
      border-radius: 15px;
      width: 100%;
      height: 3px;
      z-index: 30;
      position: relative;
      margin: 0 10px;
    } */
    
    /* .time_progressBar {
      border-radius: 15px;
      background-color: black;
      height: 100%;
    } */
    
  
  /* /////////////////////////////media queries///////////////////// */
  
  
  /* For screens between 1280px and 1919px */
  @media screen and (min-width: 1280px) and (max-width: 1919px) {
  
    .volume-container input[type="range"] {
      margin-right: 15px;
      height: 5px;
      border-radius: 5px;
    }
    
    /* Input Thumb */
    .volume-container input[type="range"]::-webkit-slider-thumb {
      height: 12px;
      width: 12px;
    }
    
    .volume-container input[type="range"]::-moz-range-thumb {
      height: 12px;
      width: 12px;
    }
    
    .volume-container input[type="range"]::-ms-thumb {
      height: 12px;
      width: 12px;
    }
  
    .volume-icon-box:hover .volume-slider{
      width: 65px;
    }
    
  }
  
  /* For screens between 960px and 1279px */
  @media screen and (min-width: 960px) and (max-width: 1279px) {
  
    .volume-container input[type="range"] {
      margin-right: 15px;
      height: 4px;
      border-radius: 5px;
    }
    
    /* Input Thumb */
    .volume-container input[type="range"]::-webkit-slider-thumb {
      height: 10px;
      width: 10px;
    }
    
    .volume-container input[type="range"]::-moz-range-thumb {
      height: 10px;
      width: 10px;
    }
    
    .volume-container input[type="range"]::-ms-thumb {
      height: 10px;
      width: 10px;
    }
  
    .volume-icon-box:hover .volume-slider{
      width: 55px;
    }
  }
  
  /* For screens between 600px and 959px */
  /* @media screen and (min-width: 600px) and (max-width: 959px) {
    .volume-container input[type="range"] {
      margin-right: 15px;
      width: 200px;
      height: 3px;
      border-radius: 5px;
    }
    
  
    .volume-container input[type="range"]::-webkit-slider-thumb {
      height: 8px;
      width: 8px;
    }
    
    .volume-container input[type="range"]::-moz-range-thumb {
      height: 8px;
      width: 8px;
    }
    
    .volume-container input[type="range"]::-ms-thumb {
      height: 8px;
      width: 8px;
    }
  } */
  
  /* For screens below 600px */
  @media screen and (max-width: 959px) {
    .volume-container input[type="range"] {
      margin-right: 15px;
      height: 3px;
      border-radius: 5px;
    }
    
    /* Input Thumb */
    .volume-container input[type="range"]::-webkit-slider-thumb {
      height: 8px;
      width: 8px;
    }
    
    .volume-container input[type="range"]::-moz-range-thumb {
      height: 8px;
      width: 8px;
    }
    
    .volume-container input[type="range"]::-ms-thumb {
      height: 8px;
      width: 8px;
    }
  
    .volume-icon-box:hover .volume-slider{
      width: 44px;
    }
  }
  
  