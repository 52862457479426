/* App.css */

  
  
  .user-files-responsive-table {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed; /* Ensures fixed layout for consistent column widths */
  }
  
 
  
  .user-files-responsive-table th,
  .user-files-responsive-table td {
    padding: 8px 5px;
    text-align: left;
    word-wrap: break-word; /* Ensures content wraps within cells */
  }
  
  .user-files-responsive-table tr {
    border-bottom: 1px solid #ddd; /* Horizontal line between rows */
  }
  
  .fileinfo {
    display: flex;
    flex-direction: column;
  }
  
  .filename {
    font-weight: bold;
  }
  
  .filetype {
    font-size: 0.9em;
    color: #333;
    margin-top: 2px;
  }
  
  .filedetails {
    font-size: 0.85em;
    color: #555;
    margin-top: 4px;
  }
  
  .options {
    text-align: right;
    cursor: pointer;
    color: #555;
  }
  
  /* Remove vertical borders */
  .user-files-responsive-table th,
  .user-files-responsive-table td {
    border: none; /* No vertical lines */
  }
  
  /* Responsive Styles for devices (max-width: 800px) */
  @media screen and (max-width: 800px) {
    /* Use Flexbox for thead to align with tbody */
    .user-files-responsive-table thead {
      display: flex;
      width: 100%;
    }
  
    .user-files-responsive-table thead th:nth-child(1) {
      flex: 1 1 10%; 
      padding: 12px 5px;
      justify-content: flex-start;
      
    }
  
    .user-files-responsive-table thead th:nth-child(2) {
      flex: 4 1 70%; /* name */
      justify-content: flex-start;
      display: flex;
      align-items: center;
    }

  
  
    /* Use Flexbox for tbody rows */
    .user-files-responsive-table tbody tr {
      display: flex;
      width: 100%;
      flex-wrap: wrap;
      padding: 10px 0;
      box-sizing: border-box;
    }
  
    .user-files-responsive-table tbody tr:last-child {
      border-bottom: none;
   
    }
  
    .user-files-responsive-table tbody tr td:nth-child(1) {
      flex: 1 1 10%; /* Name column */
      padding: 5px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  
    .user-files-responsive-table tbody tr td:nth-child(2){
      flex: 4 1 70%; /* File Type and Options */
      justify-content: flex-start;
      align-items: center;
    }

  
  
    /* Specific styles for Options column */
    .user-files-responsive-table tbody tr td[data-label="Options"] {
      justify-content: center;
      align-items: center;
    }
  
    /* Adjust text alignment */
    .user-files-responsive-table tbody tr td {
      text-align: left;
    }
  
    .options {
      text-align: center;
    }
  }
  
  /* Further Adjustments for Small Screens (max-width: 600px) */
  @media screen and (max-width: 600px) {
    /* Further adjust column widths for smaller screens */
    .user-files-responsive-table thead th:nth-child(1) {
      flex: 1 1 10%; /* checkbox column */
    }
  
    .user-files-responsive-table thead th:nth-child(2) {
      flex: 4 1 70%; /* name */
      justify-content: start;
      display: flex;
      align-items: center;
    }

  
  
    .user-files-responsive-table tbody tr td:nth-child(1) {
      flex: 1 1 10%; /* Name column */
    }
  
    
  
    .user-files-responsive-table tbody tr td:nth-child(2) {
      flex: 4 1 70%; /* File Type and Options columns */
      justify-content: start;
    }
    
  }

  /* @media screen and (max-width: 500px) {
 
    .user-files-responsive-table thead th:nth-child(1) {
      flex: 1 1 12%; 
    }
  
    .user-files-responsive-table thead th:nth-child(2) {
      flex: 4 1 70%; 
      justify-content: start;
  
    }

  
    .user-files-responsive-table tbody tr td:nth-child(1) {
      flex: 1 1 10%; 
    }
  
    
  
    .user-files-responsive-table tbody tr td:nth-child(2) {
      flex: 4 1 70%; 
      justify-content: start;
      max-width: 251px;
    }

    .user-files-responsive-table tbody tr td:nth-child(2) p{
     
      justify-content: start;
      max-width: 250px;
    }

    
  } */

  /* @media screen and (max-width: 450px) {
 
  
    .user-files-responsive-table thead th:nth-child(1) {
      max-width: 55px;
      min-width: 55px;
    }
  
    .user-files-responsive-table thead th:nth-child(2) {
      justify-content: start;
      max-width: 240px;
    }

    .user-files-responsive-table thead th:nth-child(3) {
      max-width: 45px;
      min-width: 45px;
      }
  
    .user-files-responsive-table tbody tr td:nth-child(1) {
      max-width: 55px;
      min-width: 55px;
    }
  
    
  
    .user-files-responsive-table tbody tr td:nth-child(2) {
      justify-content: start;
      max-width: 240px;
    }

    .user-files-responsive-table tbody tr td:nth-child(2) p{
      justify-content: start;
      max-width: 240px;
    }

    .user-files-responsive-table tbody tr td:nth-child(3) {
       max-width: 45px;
       min-width: 45px;
      }
  } */
  
  /* Further Adjustments for Very Small Screens (max-width: 350px) */
  @media screen and (max-width: 400px) {
 
  
    .user-files-responsive-table thead th:nth-child(1) {
      max-width: 50px;
      min-width: 50px;
    }
  
    .user-files-responsive-table thead th:nth-child(2) {
      /* flex: 4 1 80%;  */
      justify-content: start;
      max-width: 215px;
    }

    .user-files-responsive-table thead th:nth-child(3) {
      max-width: 40px;
      min-width: 40px;
      }
  
    .user-files-responsive-table tbody tr td:nth-child(1) {
      max-width: 50px;
      min-width: 50px;
    }
  
    
  
    .user-files-responsive-table tbody tr td:nth-child(2) {
      /* flex: 4 1 80%;  */
      justify-content: start;
      max-width: 215px;
    }

    .user-files-responsive-table tbody tr td:nth-child(2) p{
      /* flex: 4 1 80%;  */
      justify-content: start;
      max-width: 215px;
    }

    .user-files-responsive-table tbody tr td:nth-child(3) {
       max-width: 40px;
       min-width: 40px;
      }
  }
  
    