:root {
  --primary-color: #00D744;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -ms-overflow-style: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body ::-webkit-scrollbar {
  display: none;
}
/*
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: rgb(0, 28, 35);
} 

::-webkit-scrollbar-thumb {
  background-color: #f26641;
  outline: 1px solid slategrey;
  border-radius: 20px;
}
::-webkit-scrollbar-button {
  background-color: rgb(0, 28, 35);

  color: white;
}
::-webkit-scrollbar-button:single-button {
  background-color: rgb(0, 28, 35);

  display: block;
  background-size: 10px;
  background-repeat: no-repeat;
}
::-webkit-scrollbar-button:single-button:vertical:decrement {
  height: 12px;
  width: 16px;
  background-position: center 4px;
  color: white;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='120' fill='rgb(256,256,256)'><polygon points='50 0, 0 50, 100 50'/></svg>");
}

::-webkit-scrollbar-button:single-button:vertical:increment {
  height: 12px;
  width: 16px;
  background-position: center 4px;
  color: white;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='120' fill='rgb(256,256,256)'><polygon points='0 0, 50 50, 100 0'/></svg>");
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Flex classes */

.flex {
  display: flex;
}
.f-wrap {
  flex-wrap: wrap;
}
.row {
  flex-direction:row;
}
.col {
  flex-direction: column;
}
.j-center {
  justify-content: center;
}
.j-between {
  justify-content: space-between;
}
.j-start {
   justify-content: flext-start;
}
.j-end {
   justify-content: flex-end;
}
.a-center {
  align-items: center;
}
.a-start {
  align-items: flex-start;
}
.a-end {
  align-items: flex-end;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: transparent;
  height: 80% !important;
  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}
.swiper-slide-active{
  text-align: center;
  font-size: 18px;
  background: transparent;
  height: 80% !important;
  /* Center slide text vertica lly */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-pagination-bullet {
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  font-size: 12px;
  color: #000 !important;
  opacity: 1 !important;
  background: #000 !important;
}

/* .swiper-pagination-bullet{
  background-color: #000;
  opacity: 1;
} */

.swiper-pagination-bullet-active {
  background-color: var(--primary-color) !important;
  opacity: 1;
}

