:root {
  --primary-color: #00D744;
  --primary-light: #00D74470 /* Default color */
}

.calendar{
  display: flex;
  flex-direction: column;
  width: 70%;
  padding: 0.5rem;
  min-height: 240px;
  max-height: 240px;
  font-size: 13px;
  color: black;
}
.calendar_header{
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-top: 10px;
}
.header_info{
  text-align: center;
  min-width: 120px;
}
.inline{
  display: inline-block;
}
.day-date{
  margin: 10px;
  padding-top: 10px;
}
.days{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-weight: 500;
}
.weekdays{
  text-align: center;
  min-width: 1rem;
}
.dates{
  margin-top: 10px;
  font-size: inherit;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.calendar-rows{
  text-align: left;
  display: flex;
  justify-content: space-between;
  margin-bottom: 2px;
}
.calendar-column{
  min-width: 1.2rem;
  text-align: center;
  padding: 2px;
}
.prev-month, .next-month {
  color: grey;
}
.current-date{
  border: 1px solid red;
  border-radius: 0.5em;
}
.selected-date{
  background-color: var(--primary-color);
  color: black;
  font-weight: 700;
  border-radius: 0.5em;
}
.current-month{
  cursor: pointer;
}
.current-month:hover{
  background-color:var(--primary-light);
  border-radius: 0.5em;
}
.selected-date:hover{
 background-color: var(--primary-color);
}


@media only screen and (max-width: 1919px) {
  .calendar{
      font-size: 9px;
  }
}

@media only screen and (max-width: 1280px) {
  .calendar{
    font-size: 8px;
}
}