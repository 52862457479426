:root {
  --primary-color: #00D744;
  --primary-light: #00D74470 /* Default color */
}

.timer {
  align-items: center;
  display: flex;
  justify-content: center;
  min-width: 20%;
  margin: 10px;
  padding-top: 10px;
  flex-direction: column;
  overflow: hidden;
  font-size: 12px;

}


.list::-webkit-scrollbar {
  width: 0px; 
}
.timer p:hover {
  cursor: pointer;
  background-color: var(--primary-light);
  border-radius: 5px;
}
.time-list{
  padding: 5px;
  min-width: 50px;
  max-width: 55px;
  text-align: center;
 

}
.timer .list p.selected {
  background-color: var(--primary-color);
  color: black;
  font-weight: 700;
  border-radius: 0.5em;
  /* ///////////////////// */
 
}
button {
  background-color: rgb(255, 255, 255);
  border: none;
  cursor: pointer;
  margin: 0; 
  padding: 0; 
  display: flex;
  align-items: center;
  justify-content: center;
}


@media only screen and (max-width: 1919px) {
  .timer{
      font-size: 9px;
  }
}

@media only screen and (max-width: 1280px) {
  .timer{
    font-size: 8px;
}
}
